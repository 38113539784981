import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import Lines from '../components/common/Lines';
import ProgressScroll from '../components/common/ProgressScroll';
import Cursor from '../components/common/cusor';
import LoadingScreen from '../components/common/loader';
import Footer from '../components/common/Footer';
import Navbar2 from '../components/common/Navbar2';
import JoinNowHeader from '../components/page-form/Header';
import JoinNowForm from '../components/page-form/Form';
import WOW from 'wowjs';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function PageForm() {
  const main = useRef();

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;

        script.onload = () => resolve(true);
        script.onerror = () => reject(new Error(`Failed to load ${src}`));

        document.body.appendChild(script);
      });
    };

    loadScript('/assets/js/gsap.min.js')
      .then(() => loadScript('/assets/js/ScrollSmoother.min.js'))
      .then(() => loadScript('/assets/js/smoother-script.js'))
      .catch((error) => console.error(error.message));
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      new WOW.WOW({
        animateClass: 'animated',
        offset: 100,
      }).init();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Join Now | OnlyFans Model Agency</title>
        <link rel="icon" href="/assets/imgs/favicon.ico" />
        <link rel="shortcut icon" href="/assets/imgs/favicon.ico" />
        <link rel="stylesheet" type="text/css" href="/assets/css/plugins.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/style.css" />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700&display=swap"
        />
        <script src="/assets/js/ScrollTrigger.min.js" defer />
        <script src="/assets/js/ScrollSmoother.min.js" defer />
        <script defer src="/assets/js/gsap.min.js" />
        <script defer src="/assets/js/splitting.min.js" />
        <script defer src="/assets/js/isotope.pkgd.min.js" />
        <script defer src="/assets/js/plugins.js" />
        <script defer src="/assets/js/TweenMax.min.js" />
        <script defer src="/assets/js/charming.min.js" />
        <script defer src="/assets/js/countdown.js" />
      </Helmet>
      <body>
        <LoadingScreen />
        <Cursor />
        <ProgressScroll />
        <Lines />
        <Navbar2 />
        <div id="smooth-wrapper" ref={main}>
          <div id="smooth-content">
            <main className="main-bg o-hidden">
              {/* <JoinNowHeader /> */}
              <JoinNowForm />
            </main>
            <Footer />
          </div>
        </div>
      </body>
    </>
  );
}