'use client';
import React, { useEffect } from 'react';

function Navbar2() {
  function handleScroll() {
    const bodyScroll = window.scrollY;
    const navbar = document.querySelector('.navbar');

    if (bodyScroll > 300) navbar.classList.add('nav-scroll');
    else navbar.classList.remove('nav-scroll');
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  function handleDropdownMouseMove(event) {
    event.currentTarget.querySelector('.dropdown-menu').classList.add('show');
  }

  function handleDropdownMouseLeave(event) {
    event.currentTarget
      .querySelector('.dropdown-menu')
      .classList.remove('show');
  }
  function handleToggleNav() {
    if (
      document
        .querySelector('.navbar .navbar-collapse')
        .classList.contains('show')
    ) {
      document
        .querySelector('.navbar .navbar-collapse')
        .classList.remove('show');
    } else if (
      !document
        .querySelector('.navbar .navbar-collapse')
        .classList.contains('show')
    ) {
      document.querySelector('.navbar .navbar-collapse').classList.add('show');
    }
  }
  return (
    <nav className="navbar navbar-expand-lg bord blur">
      <div className="container o-hidden">
        <a className="logo " href="/">
          <img style={{ height: 40, maxWidth: 30}} src="/assets/imgs/logo-light.png" alt="logo" />
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={handleToggleNav}
        >
          <span className="icon-bar">
            <i className="fas fa-bars"></i>
          </span>
        </button>

        <div
          className="collapse navbar-collapse justify-content-end mr-40"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav">
            {/* <li className="nav-item">
              <a className="nav-link" href="/">
                <span className="rolling-text">Home</span>
              </a>
            </li> */}
            {/* <li className="nav-item">
              <a className="nav-link" href="/page-contact">
                <span className="rolling-text">About</span>
              </a>
            </li> */}
            {/* <li
              onMouseLeave={handleDropdownMouseLeave}
              onMouseMove={handleDropdownMouseMove}
              className="nav-item dropdown"
            >
              <a
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="rolling-text">Socials</span>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="/page-about">
                    Instagram
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/page-services">
                    Facebook
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/page-services-details">
                    X
                  </a>
                </li>
                
              </ul>
            </li> */}
            {/* <li className="nav-item">
              <a className="nav-link" href="/contact">
                <span className="rolling-text">Contact</span>
              </a>
            </li> */}
            <li className="nav-item">
              <a className="nav-link" href="https://instagram.com/profilemgmt" target='_blank'>
                <span className="rolling-text">Follow Us</span>
              </a>
            </li>
            
          </ul>
        </div>

        {/* <div className="contact-button">
          <a
            href="https://app.profilemgmt.com/sign-up" target='_blank'
            className="butn butn-sm butn-bg main-colorbg radius-5"
          >
            <span className="text">Apply Now</span>
          </a>
        </div> */}
      </div>
    </nav>
  );
}

export default Navbar2;
