import React from 'react';
import './page.css'; // We'll add some CSS for styling

const SuccessPage = () => {
  return (
    <section className="success-section mt-50">
      <div className="success-container">
        <h2>APPLICATION SUBMITTED!</h2>
        <center>
          <div className="checkmark-circle">
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
              <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
          </div>
          <p className="text-u">
            Thank you, We’ll be in touch soon!
          </p>
        </center>
      </div>
    </section>
  );
};

export default SuccessPage;