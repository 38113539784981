import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/functions'; // Import Firebase functions

// Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyCBRvqK6aX8TnSNOlsc5_j-0spKHGs0yVs",
    authDomain: "profile-mgmt.firebaseapp.com",
    databaseURL: "https://profile-mgmt-default-rtdb.firebaseio.com",
    projectId: "profile-mgmt",
    storageBucket: "profile-mgmt.appspot.com",
    messagingSenderId: "998843670900",
    appId: "1:998843670900:web:228bb4e54006396565cc21",
    measurementId: "G-PE4VLXMS8W"
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}

// Initialize Firestore
const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const functions = firebase.functions(); // Initialize Firebase functions

// Export the db, auth, storage, and functions instances to use it in other files
export { auth, db, storage, functions };
