import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
//import './Form.css';

const JoinNowForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    age: false,
    social: '',
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }
  };

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    const digits = value.replace(/\D/g, '');
    let formattedPhone = '';
    if (digits.length > 0) {
      formattedPhone = `(${digits.slice(0, 3)}`;
      if (digits.length > 3) {
        formattedPhone += `) ${digits.slice(3, 6)}`;
      }
      if (digits.length > 6) {
        formattedPhone += `-${digits.slice(6, 10)}`;
      }
    }
    setFormData({
      ...formData,
      phone: formattedPhone,
    });
    if (errors.phone && formattedPhone.length === 14) {
      setErrors((prev) => ({ ...prev, phone: '' }));
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = 'Full name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Email is invalid';
    if (!formData.phone) newErrors.phone = 'Phone number is required';
    else if (formData.phone.length < 14) newErrors.phone = 'Phone number must be 10 digits';
    if (!formData.age) newErrors.age = 'You must confirm you are 18 or older';
    if (!formData.social.trim()) newErrors.social = 'Instagram username is required';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsSubmitting(true);
    try {
      await addDoc(collection(db, 'leads'), {
        ...formData,
        timestamp: serverTimestamp(),
      });
      setFormData({
        name: '',
        email: '',
        phone: '',
        age: false,
        social: '',
      });
      setErrors({});
      setIsSubmitting(false);
      window.scrollTo(0, 0);
      navigate(`/success?ig=${formData.social}`);
    } catch (error) {
      console.error('Error saving to Firestore:', error);
      setErrors({ submit: 'Submission failed. Please try again.' });
      setIsSubmitting(false);
    }
  };

  return (
    <section className="form-section mt-50 mb-100">
      <div className="form-container">
        <h2>Launch Your Career with <span className="underline-purple">Profile MGMT</span></h2>
        <center>
          <p className="text-u">
            Unlock exclusive opportunities today! Complete the form to get started with our expert team.
          </p>
          <p className="trust-text">Trusted by 300+ creators worldwide.</p>
        </center>
        <form onSubmit={handleSubmit} className="join-now-form mt-40">
          <div className="form-group">
            <label htmlFor="name">Your Name *</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter your full name"
              required
            />
            {errors.name && <span className="error">{errors.name}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="email">Email Address *</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your email"
              required
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone Number *</label>
            <input
              type="text"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handlePhoneChange}
              placeholder="(123) 456-7890"
              maxLength="14"
              required
            />
            {errors.phone && <span className="error">{errors.phone}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="social">Instagram Username *</label>
            <input
              type="text"
              id="social"
              name="social"
              value={formData.social}
              onChange={handleChange}
              placeholder="e.g., https://instagram.com/username"
            />
            {errors.social && <span className="error">{errors.social}</span>}
          </div>
          <div className="terms-group">
            <input
              type="checkbox"
              id="age"
              name="age"
              checked={formData.age}
              onChange={handleChange}
              required
            />
            <label htmlFor="age">I am 18 or older *</label>
            {errors.age && <span className="error">{errors.age}</span>}
          </div>
          <button type="submit" className="btn-color" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Get Started'}
          </button>
          {errors.submit && <span className="error submit-error">{errors.submit}</span>}
        </form>
      </div>
    </section>
  );
};

export default JoinNowForm;