import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeMain from './pages/home-main.js';
import Page404 from './pages/page-404.js';
import PageContact from './pages/page-contact.js';
import PageForm from './pages/page-form.js';
import PageSuccess from './pages/page-success.js';

// import gsap from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
// // // import { ScrollSmoother } from 'gsap-trial/ScrollSmoother';

// gsap.registerPlugin(ScrollTrigger);
// gsap.config({ trialWarn: false });
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeMain />} />
        <Route path="/page-404" element={<Page404 />} />
        <Route path="/contact" element={<PageContact />} />
        <Route path="/join" element={<PageForm />} />
        <Route path="/success" element={<PageSuccess />} />

      </Routes>
    </Router>
  );
}

export default App;
