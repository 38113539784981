import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import Lines from '../components/common/Lines';
import ProgressScroll from '../components/common/ProgressScroll';
import Cursor from '../components/common/cusor';
import LoadingScreen from '../components/common/loader';
import Footer from '../components/common/Footer';
import Navbar2 from '../components/common/Navbar2';
import SuccessPage from '../components/page-success/Form'; // Adjust path if needed

export default function PageSuccess() {
  const main = useRef();

  return (
    <>
      <Helmet>
        <title>Join Now | OnlyFans Model Agency</title>
        <link rel="icon" href="/assets/imgs/favicon.ico" />
        <link rel="shortcut icon" href="/assets/imgs/favicon.ico" />
        <link rel="stylesheet" type="text/css" href="/assets/css/plugins.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/style.css" />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700&display=swap"
        />
        {/* Keep scripts in Helmet if they’re needed globally */}
        <script src="/assets/js/gsap.min.js" defer />
        <script src="/assets/js/ScrollTrigger.min.js" defer />
        <script src="/assets/js/ScrollSmoother.min.js" defer />
        <script src="/assets/js/splitting.min.js" defer />
        <script src="/assets/js/isotope.pkgd.min.js" defer />
        <script src="/assets/js/plugins.js" defer />
        <script src="/assets/js/TweenMax.min.js" defer />
        <script src="/assets/js/charming.min.js" defer />
        <script src="/assets/js/countdown.js" defer />
      </Helmet>
      <body>
        <LoadingScreen />
        <Cursor />
        <ProgressScroll />
        <Lines />
        <Navbar2 />
        <div id="smooth-wrapper" ref={main}>
          <div id="smooth-content">
            <main className="main-bg o-hidden">
              <SuccessPage />
            </main>
            <Footer />
          </div>
        </div>
      </body>
    </>
  );
}